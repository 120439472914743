export const categories = [
    {
      title: "Rent2Go",
      description: "Rent2Go is a rent a car platform developed via React Typescript & Java Spring Boot.",
      imageUrl: "/assets/images/category-thumbs/rentogo.png",
    },
    {
      title: "FKE EduHub",
      description: "FKE EduHub is a education management system developed via React Typescript & Java Spring Boot. ",
      imageUrl: "/assets/images/category-thumbs/eduhub.png",
    },
    {
      title: "FKE Linear Regression",
      description: "FKE Linear Regression is a calculator tool from simple linear regression developed via React Typescript & Java Spring Boot. ",
      imageUrl: "/assets/images/category-thumbs/regression.png",
    },
    {
      title: "FKE Radio",
      description: "FKE Radio is a web radio tool developed via React js. ",
      imageUrl: "/assets/images/category-thumbs/radio.png",
    },
    {
      title: "FKE Trivia Game",
      description: "FKE Trivia Game is a random quiz web app developed via React js. ",
      imageUrl: "/assets/images/category-thumbs/quiz.jpg",
    },
    {
      title: "FKE CMS",
      description: "FKE CMS is an Admin dashboard for content management system developed via PHP Codeigniter.",
      imageUrl: "/assets/images/category-thumbs/cms.png",
    },
  ];