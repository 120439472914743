import React from "react";


import "./App.css"
import { Layout } from "./Pages";


function App() {
  return (
    <>
      <Layout />
    </>
  );
}

export default App;
